import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import AddNewHolidaySys from "./AddNewHolidaySys";

const AddHolyModal = ({
  title,
  editTitle,
  className,
  id,
  open,
  handleClose,
  data,
  isEmployee,

  ...rest
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick" && reason !== "escapeKeyDown") {
            handleClose(id);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="ta-modal "
      >
        <DialogTitle id="alert-dialog-title" className="modal_header">
          <span className="title-modals"> {data ? editTitle : title} </span>
          <div className="close_button">
            <CloseIcon onClick={() => handleClose(id)} />
          </div>
        </DialogTitle>
        <DialogContent>
          <AddNewHolidaySys
            handleClose={handleClose}
            id={id}
            event={data}
            setNoticeDeleteHoliday={data?.setNoticeDeleteHoliday}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddHolyModal;
